<template>
  <v-app-bar app clipped-left v-if="isAuthenticated" dark>
    <v-app-bar-nav-icon class="d-flex" @click.stop="$emit('toggle-mini')"></v-app-bar-nav-icon>
    <div class="d-none d-md-inline-block">
      <v-img flat to="/orders" width="276px" contain :src="require('@/assets/GTPConnectReversed.png')"></v-img>
    </div>
    <div class="d-inline-block d-md-none">
      <v-img width="276px" contain :src="require('@/assets/GTPConnectReversed.png')"></v-img>
    </div>
    <bread-crumbs></bread-crumbs>
    <v-spacer class="d-none d-sm-flex"></v-spacer>
    <v-chip label pill v-if="user.trialExpiration != null" class="mr-4" color="trialexpiration">
      <div>{{ 'Trial Expires: ' + $utilities.printDate(user.trialExpiration) }}</div>
    </v-chip>
    <v-select
      :loading="customersLoading"
      :items="customers"
      @input="selectCustomer"
      v-model="localSelectedCustomer"
      item-text="name"
      item-value="id"
      label="Customer"
      class="customer-select mt-1 mr-2"
      @click.stop
    ></v-select>
    <v-badge
      :bordered="notificationBadge > 0"
      overlap
      :color="notificationBadge > 0 ? 'error' : 'transparent'"
      :value="notificationBadge"
      :content="notificationBadge > 9 ? '9+' : notificationBadge"
      offset-x="25"
      offset-y="25"
    >
      <v-btn icon @click="$emit('toggle-notifications')" :disabled="notificationBadge === 0"
        ><v-icon>notifications</v-icon></v-btn
      ></v-badge
    >
    <v-menu class="" bottom left>
      <template v-slot:activator="{ on }">
        <v-btn text class="">
          <v-avatar color="red darken-2" v-on="on" size="35">
            <v-img :src="profile.picture" v-if="profile.picture?.length > 0"></v-img>
            <span v-else>{{ avatarInitials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/account">
          <v-icon>account_circle</v-icon>
          <v-list-item-title>My Account</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-btn @click="$emit('logout')"><v-icon>lock</v-icon> Sign Out</v-btn>
        </v-list-item>
        <v-list-item class="caption"> Version: <br />{{ version }} </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  <v-app-bar app clipped-left v-else dark>
    <div class="d-none d-md-inline-block mr-4">
      <v-img flat to="/orders" width="276px" contain :src="require('@/assets/GTPConnectReversed.png')"></v-img>
    </div>
    <div class="d-inline-block d-md-none mr-4">
      <v-img width="276px" contain :src="require('@/assets/GTPConnectReversed.png')"></v-img>
    </div>
    <v-toolbar-title></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon>account_circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn @click="$emit('login')"><v-icon>lock</v-icon> Log In</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script lang="ts">
interface VuexBindings {
  version: string;
}

import BreadCrumbs from '@/navigation/BreadCrumbs.vue';
import { IdTokenClaims } from '@/auth/idTokenClaims';
import { mapActions, mapState } from 'vuex';
import UserState from '@/store/user/userState';
import Vue, { VueConstructor } from 'vue';

const TopNav = (Vue as VueConstructor<Vue & VuexBindings & UserState>).extend({
  data() {
    return {
      customersLoading: true,
      localSelectedCustomer: null as string | null,
    };
  },
  props: {
    isAuthenticated: {
      type: Boolean,
    },
    notificationBadge: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BreadCrumbs,
  },
  computed: {
    ...mapState({ version: 'version' }),
    ...mapState<UserState>('userStore', {
      customers: 'customers',
      profile: 'profile',
      selectedCustomer: 'selectedCustomer',
      user: 'user',
    }),
    avatarInitials(): string {
      return (this.profile as IdTokenClaims)?.given_name?.substring(0, 1) + this.profile?.family_name?.substring(0, 1);
    },
  },
  methods: {
    ...mapActions('userStore', ['setSelectedCustomer']),
    selectCustomer() {
      this.setSelectedCustomer(this.localSelectedCustomer);
    },
  },
  watch: {
    selectedCustomer: function (selectedCustomer: string) {
      this.localSelectedCustomer = selectedCustomer;
    },
    customers: function () {
      this.customersLoading = false;
    },
  },
});
export default TopNav;
</script>

<style scoped lang="scss">
.customer-select {
  font-size: 14px;
  height: 32px;
  max-width: 15%;
  min-width: 200px;
  .v-input__control {
    height: 32px;
    .v-input__slot {
      min-height: 32px;
      .v-select__slot {
        height: 32px;
        padding-top: 2px;
        .v-input__append-inner {
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
