var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isAuthenticated
    ? _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", dark: "" } },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "d-flex",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("toggle-mini")
              },
            },
          }),
          _c(
            "div",
            { staticClass: "d-none d-md-inline-block" },
            [
              _c("v-img", {
                attrs: {
                  flat: "",
                  to: "/orders",
                  width: "276px",
                  contain: "",
                  src: require("@/assets/GTPConnectReversed.png"),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-inline-block d-md-none" },
            [
              _c("v-img", {
                attrs: {
                  width: "276px",
                  contain: "",
                  src: require("@/assets/GTPConnectReversed.png"),
                },
              }),
            ],
            1
          ),
          _c("bread-crumbs"),
          _c("v-spacer", { staticClass: "d-none d-sm-flex" }),
          _vm.user.trialExpiration != null
            ? _c(
                "v-chip",
                {
                  staticClass: "mr-4",
                  attrs: { label: "", pill: "", color: "trialexpiration" },
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        "Trial Expires: " +
                          _vm.$utilities.printDate(_vm.user.trialExpiration)
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("v-select", {
            staticClass: "customer-select mt-1 mr-2",
            attrs: {
              loading: _vm.customersLoading,
              items: _vm.customers,
              "item-text": "name",
              "item-value": "id",
              label: "Customer",
            },
            on: {
              input: _vm.selectCustomer,
              click: function ($event) {
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.localSelectedCustomer,
              callback: function ($$v) {
                _vm.localSelectedCustomer = $$v
              },
              expression: "localSelectedCustomer",
            },
          }),
          _c(
            "v-badge",
            {
              attrs: {
                bordered: _vm.notificationBadge > 0,
                overlap: "",
                color: _vm.notificationBadge > 0 ? "error" : "transparent",
                value: _vm.notificationBadge,
                content:
                  _vm.notificationBadge > 9 ? "9+" : _vm.notificationBadge,
                "offset-x": "25",
                "offset-y": "25",
              },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", disabled: _vm.notificationBadge === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle-notifications")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("notifications")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-menu",
            {
              attrs: { bottom: "", left: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          { attrs: { text: "" } },
                          [
                            _c(
                              "v-avatar",
                              _vm._g(
                                {
                                  attrs: { color: "red darken-2", size: "35" },
                                },
                                on
                              ),
                              [
                                _vm.profile.picture?.length > 0
                                  ? _c("v-img", {
                                      attrs: { src: _vm.profile.picture },
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.avatarInitials)),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1925404866
              ),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: "/account" } },
                    [
                      _c("v-icon", [_vm._v("account_circle")]),
                      _c("v-list-item-title", [_vm._v("My Account")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$emit("logout")
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("lock")]), _vm._v(" Sign Out")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-list-item", { staticClass: "caption" }, [
                    _vm._v(" Version: "),
                    _c("br"),
                    _vm._v(_vm._s(_vm.version) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", dark: "" } },
        [
          _c(
            "div",
            { staticClass: "d-none d-md-inline-block mr-4" },
            [
              _c("v-img", {
                attrs: {
                  flat: "",
                  to: "/orders",
                  width: "276px",
                  contain: "",
                  src: require("@/assets/GTPConnectReversed.png"),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-inline-block d-md-none mr-4" },
            [
              _c("v-img", {
                attrs: {
                  width: "276px",
                  contain: "",
                  src: require("@/assets/GTPConnectReversed.png"),
                },
              }),
            ],
            1
          ),
          _c("v-toolbar-title"),
          _c("v-spacer"),
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-menu",
                {
                  attrs: { bottom: "", left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { text: "" } }, on),
                            [_c("v-icon", [_vm._v("account_circle")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("login")
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("lock")]), _vm._v(" Log In")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }