<template>
  <div>
    <v-breadcrumbs class="d-sm-none d-inline-block" :items="[routeItem]">
      <template v-slot:item="props">
        <v-breadcrumbs-item :id="props.item.name">
          <div class="d-none d-sm-inline-block" v-if="props.item === supplierItem" transparent>
            {{ supplierName }}
            <div class="d-inline-block" v-if="branchName">
              <span class="v-breadcrumbs__divider ml-2 mr-2">/</span> {{ branchName }}
            </div>
          </div>
          <span v-if="props.item === routeItem">{{ $route.name }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-breadcrumbs class="d-none d-sm-inline-block" :items="[supplierItem, routeItem]">
      <template v-slot:item="props">
        <v-breadcrumbs-item :id="props.item.name">
          <div class="d-none d-sm-inline-block" v-if="props.item === supplierItem" transparent>
            {{ supplierName }}
            <div class="d-inline-block" v-if="branchName">
              <span class="v-breadcrumbs__divider ml-2 mr-2">/</span> {{ branchName }}
            </div>
          </div>
          <span v-if="props.item === routeItem">{{ $route.name }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<style lang="scss">
#branch .v-breadcrumbs__item .v-input {
  padding-top: 30px;

  .v-input__slot {
    padding-left: 0px;
    padding-right: 0px;
  }
}

#branch-selector {
  width: 25px;
}
</style>

<script lang="ts">
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import UserSettingsModel from '../domainmodels/userSettingsModel';
import { VueConstructor } from 'vue';
import BranchModel from '../domainmodels/branchModel';
import { EnumObject } from '../domainmodels/enums';

interface VuexBindings {
  user: UserSettingsModel;
  branches: BranchModel[];
  updateSupplierId(value: string): void;
  updateBranchId(value: string): void;
}

const BreadCrumbs = (Vue as VueConstructor<Vue & VuexBindings>).extend({
  data() {
    return {
      supplierItem: {
        name: 'supplier',
      },

      routeItem: {
        name: 'route',
      },
    };
  },
  computed: {
    ...mapState('userStore', {
      branches: 'branches',
      user: 'user',
    }),

    suppliers(): EnumObject[] {
      return this.user.availableSuppliers;
    },

    supplierName(): string {
      let supplierName = '';
      const supplier = (this.user as UserSettingsModel).availableSuppliers.find((x) => {
        return x.value === this.user.currentSupplierId;
      });
      if (supplier) {
        supplierName = supplier.text;
      }
      return supplierName;
    },

    supplierId: {
      get(): string {
        return this.user.currentSupplierId;
      },
      set(supplierId: string) {
        this.updateSupplierId(supplierId);
      },
    },

    branchId: {
      get(): string {
        return this.user.currentBranchId;
      },
      set(value: string) {
        this.updateBranchId(value);
      },
    },

    branchName: {
      get(): string {
        const branch = (this.branches as BranchModel[]).find((x) => {
          return x.id === this.branchId;
        });
        return branch ? branch.name : '';
      },
    },
  },
  methods: {
    ...mapActions('userStore', {
      updateSupplierId: 'updateSupplierId',
      updateBranchId: 'updateBranchId',
    }),
  },
});
export default BreadCrumbs;
</script>
