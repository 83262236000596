import BranchModel from '@/domainmodels/branchModel';
import Customer from '@/customers/customer';
import { IdTokenClaims } from '@/auth/idTokenClaims';
import UserSettingsModel from '@/domainmodels/userSettingsModel';

export default class UserState {
  public branches: BranchModel[];
  public customers: Customer[];
  public profile?: IdTokenClaims;
  public selectedCustomer: string | null;
  public user: UserSettingsModel;

  constructor() {
    this.branches = [] as BranchModel[];
    this.customers = [] as Customer[];
    this.selectedCustomer = null;
    this.user = new UserSettingsModel();
  }
}
