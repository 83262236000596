var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-breadcrumbs", {
        staticClass: "d-sm-none d-inline-block",
        attrs: { items: [_vm.routeItem] },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c("v-breadcrumbs-item", { attrs: { id: props.item.name } }, [
                  props.item === _vm.supplierItem
                    ? _c(
                        "div",
                        {
                          staticClass: "d-none d-sm-inline-block",
                          attrs: { transparent: "" },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.supplierName) + " "),
                          _vm.branchName
                            ? _c("div", { staticClass: "d-inline-block" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "v-breadcrumbs__divider ml-2 mr-2",
                                  },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" " + _vm._s(_vm.branchName) + " "),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  props.item === _vm.routeItem
                    ? _c("span", [_vm._v(_vm._s(_vm.$route.name))])
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("v-breadcrumbs", {
        staticClass: "d-none d-sm-inline-block",
        attrs: { items: [_vm.supplierItem, _vm.routeItem] },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c("v-breadcrumbs-item", { attrs: { id: props.item.name } }, [
                  props.item === _vm.supplierItem
                    ? _c(
                        "div",
                        {
                          staticClass: "d-none d-sm-inline-block",
                          attrs: { transparent: "" },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.supplierName) + " "),
                          _vm.branchName
                            ? _c("div", { staticClass: "d-inline-block" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "v-breadcrumbs__divider ml-2 mr-2",
                                  },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" " + _vm._s(_vm.branchName) + " "),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  props.item === _vm.routeItem
                    ? _c("span", [_vm._v(_vm._s(_vm.$route.name))])
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }