import _Vue from 'vue';
import authService from '../auth/authService';

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function AuthPlugin(Vue: typeof _Vue): void {
  // do stuff with options
  Vue.prototype.$auth = authService;
}

export class AuthPluginOptions {
  // add stuff
}
