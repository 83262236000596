import { AuthPlugin } from './plugins/auth';
import { AuthService } from './auth/authService';
import { AxiosPlugin } from './plugins/axios';
import { AxiosStatic } from 'axios';
import App from './App.vue';
import L, { Icon } from 'leaflet';
import router from './router';
import store from './store';
import { Utilities } from './utilities/utilities';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import { VueUtilities } from './plugins/utilities';
import './plugins/vuetify';
import 'leaflet/dist/leaflet.css'; // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/dist/vuetify.min.css'; // Ensure you are using css-loader

Vue.config.productionTip = false;

Vue.use(AxiosPlugin);
Vue.use(AuthPlugin);
Vue.use(VueUtilities);

declare module 'vue/types/vue' {
  interface Vue {
    $auth: AuthService;
    $http: AxiosStatic;
    $httpCache: AxiosStatic;
    $httpCacheClient: AxiosStatic;
    $openApi: string;
    $supplierApi: string;
    $utilities: Utilities;
  }
}

delete (
  L.Icon.Default.prototype as Icon.Default & {
    _getIconUrl?: string;
  }
)._getIconUrl; // fixes missing marker icons

export const bus = new Vue();

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
