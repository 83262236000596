<template>
  <v-layout class="bg-warehouse">
    <v-flex align-self-center grow="1" class="full-width">
      <v-card class="center-dialog align-center justify-center bg-white ph-2">
        <v-card-title class="text-center">
          <v-row
            ><v-col cols="1"></v-col>
            <v-col cols="10"
              ><v-img height="85px" aspect-ratio="4.45 " :src="require('@/assets/GTPConnect.png')"></v-img
            ></v-col>
            <v-col cols="1"></v-col
          ></v-row>
        </v-card-title>
        <v-card-text class="text-center">
          <h1 class="primary--text"><i>SOAR Above the Disconnect</i></h1>
          <v-row>
            <v-col cols="2"></v-col>
            <v-col cols="8" class="text-left"
              ><h3><v-icon class="mr-2" color="primary" dark>check_circle</v-icon>Strengthening Partnerships</h3>
              <h3><v-icon class="mr-2" color="primary" dark>check_circle</v-icon>On Demand Communications</h3>
              <h3><v-icon class="mr-2" color="primary" dark>check_circle</v-icon>Access to Information</h3>
              <h3>
                <v-icon class="mr-2" color="primary" dark>check_circle</v-icon>Redefining Material Logistics
              </h3></v-col
            >
            <v-col cols="2"></v-col>
          </v-row>
          <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <v-btn color="primary" @click="$emit('login')">Login</v-btn>
              <p class="mt-1"><a href="mailto:sales@gogtp.com">Request an account</a></p>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Login extends Vue {}
</script>

<style lang="scss">
.bg-warehouse {
  background-image: url('../assets/warehouse.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
  height: 100vh;
  width: 100vw;
  margin: -12px;
}
.center-dialog {
  width: 500px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}
.full-width {
  width: 100%;
}
</style>
