import { PurchasingRequestType } from '@/domainmodels/enums';
import JobAccountLink from '@/joblinking/jobAccountLink';
import PurchasingRequest from '@/requests/purchasingRequest';
import SupplierAvailability from '@/requests/supplierAvailability';
import SupplierPurchaseOrder from '@/requests/supplierPurchaseOrder';
import SupplierQuote from '@/requests/supplierQuote';
import SupplierShipment from '@/domainmodels/supplierShipment';
export default class RequestState {
  public selectedRequest: PurchasingRequest | undefined;
  public requestType: PurchasingRequestType;
  public respondMode: boolean;
  public selectedResponse: SupplierQuote | SupplierAvailability | SupplierPurchaseOrder | null;
  public showShipping: boolean;
  public shippingReadOnly: boolean;
  public selectedShipment: SupplierShipment | undefined;
  public jobLinkInfo: JobAccountLink;
  public checkResponseValid: boolean;
  public checkingForResponses: boolean;

  constructor() {
    this.selectedRequest = undefined;
    this.requestType = PurchasingRequestType.Quote;
    this.respondMode = false;
    this.selectedResponse = {} as SupplierQuote | SupplierAvailability | SupplierPurchaseOrder;
    this.showShipping = false;
    this.shippingReadOnly = false;
    this.selectedShipment = undefined;
    this.jobLinkInfo = {} as JobAccountLink;
    this.checkResponseValid = false;
    this.checkingForResponses = false;
  }
}
