export enum PurchasingRequestType {
  Quote = 1,
  Pricing = 2,
  Availability = 3,
  'Purchase Order' = 4,
}

export enum LinearPropertyDataType {
  Feet = 4,
  Inches = 5,
  Millimeters = 10,
  Meters = 13,
  Centimeters = 33,
}

export enum LinearPropertyDataTypeAbbreviation {
  'ft' = 4,
  'in' = 5,
  'mm' = 10,
  'm' = 13,
  'cm' = 33,
}

//This enum is also in STRATUS, but the
//verbiage is changed here to reflect status to the supplier
export enum PurchasingRequestStatus {
  Unsent = 1,
  Requested = 2,
  Acknowledged = 3,
  Problem = 4,
  Declined = 5,
  Cancelled = 6,
  Responded = 7,
  'Pending Shipment' = 8,
  Shipped = 9,
  'Partially Shipped' = 10,
  Delivered = 11,
  'Partially Delivered' = 12,
  'Awaiting Payment' = 13,
  'Pending Payment' = 14,
  Paid = 15,
  'Partially Paid' = 16,
  Completed = 17,
  Archived = 18,
  'Quote Responded' = 19,
  'PO Responded' = 20,
}

export enum ShipmentIssueReason {
  Unspecified = 1,
  Damaged = 2,
  'Over Qty' = 3,
  'Under Qty' = 4,
  'Wrong Item' = 5,
  Other = 6,
}

export enum DocumentStorageType {
  Unspecified = 1,
  ExternalHyperlink = 2,
  AzureBlob = 3,
}

export enum ReferenceType {
  Unspecified = 0,
  Part = 1,
  Assembly = 2,
  Model = 3,
  Package = 4,
  Project = 5,
  FormTemplate = 6,
  User = 7,
  Company = 8,
  TaskItem = 9,
  BOM = 10,
  Container = 11,
  Tool = 12,
  Station = 13,
  Processor = 14,
  OrderProcessorItem = 15,
  Asset = 16,
  PackageDetails = 17,
  Purchasing = 18,
  Invoice = 19,
  MasterReport = 20,
  ContainerDetails = 21,
}

export enum DocumentFileType {
  Unspecified = 1,
  PDF = 2,
  PNG = 3,
  JPG = 4,
  JPEG = 5,
  BMP = 6,
  GIF = 7,
  TXT = 8,
  CSV = 9,
  XLS = 10,
  XLSX = 11,
  DOC = 12,
  DOCX = 13,
  MP4 = 14,
}

export enum ManufactureSourceType {
  Unspecified = 0,
  DomesticAmerican = 1,
  DomesticAmericas = 2,
  Foreign = 3,
}

export enum ReportFieldFormat {
  String = 0,
  Integer = 1,
  Decimal = 2,
  FeetInch = 3,
  FeetInchFraction = 4,
  Inch = 5,
  InchFraction = 6,
  Date = 7,
  Boolean = 8,
}

export enum MarketplaceUnitOfMeasure {
  Bundle,
  Bag,
  Bank,
  Barrel,
  Bottle,
  Box,
  Can,
  Case,
  Centimeter,
  Coil,
  CubicFeet,
  Cylinder,
  Dozen,
  Drum,
  Each,
  Feet,
  Gallon,
  Inches,
  Kilograms,
  Lot,
  Meter,
  Ounce,
  PerHundred,
  PerThousand,
  Pounds,
  Piece,
  Pack,
  Pail,
  Pair,
  Pallet,
  Pint,
  Quart,
  Roll,
  Set,
  Stick,
  SquareFoot,
  SquareYard,
  Tube,
  Yard,
  None,
}

export enum TimePeriod {
  None = 0,
  DateRange,
  PreviousMonth,
  MTD,
  YTD,
}

export enum BillingDownloadSearchType {
  None = 0,
  StratusPONumber,
  SupplierInvoiceNumbers,
  SupplierPONumber,
  SupplierShipmentNumbers,
}

export class EnumObject {
  public value: number | string;
  public text: string;
  constructor(value: number | string, text: string) {
    this.value = value;
    this.text = text;
  }
}
