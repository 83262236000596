import * as msal from '@azure/msal-browser';

import { EventEmitter } from 'events';

const forgotPasswordErrorCode = 'AADB2C90118';
const loginEvent = 'loginEvent';

export class AuthService extends EventEmitter {
  public isAuthenticated = false;
  private msalInstance: msal.PublicClientApplication;
  private scopes: string[] = ['openid', 'profile'];
  private token: msal.AuthenticationResult | null = null;

  constructor() {
    super();
    const msalConfig: msal.Configuration = {
      auth: {
        authority:
          `https://${process.env.VUE_APP_AZUREADB2C_TENANT_NAME}.b2clogin.com/` +
          `${process.env.VUE_APP_AZUREADB2C_TENANT_NAME}.onmicrosoft.com/` +
          `${process.env.VUE_APP_AZUREADB2C_SIGNUPSIGNIN_POLICY_ID}`,
        clientId: process.env.VUE_APP_CLIENT_ID ?? '',
        knownAuthorities: [`${process.env.VUE_APP_AZUREADB2C_TENANT_NAME}.b2clogin.com`],
        postLogoutRedirectUri: `${window.location.origin}/login`,
        redirectUri: `${window.location.origin}/callback`,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
            if (containsPii) return;
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message);
                break;
              case msal.LogLevel.Info:
                console.info(message);
                break;
              case msal.LogLevel.Verbose:
                console.debug(message);
                break;
              case msal.LogLevel.Warning:
                console.warn(message);
                break;
            }
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Verbose,
        },
      },
    };
    this.msalInstance = new msal.PublicClientApplication(msalConfig);
    const accounts: msal.AccountInfo[] = this.msalInstance.getAllAccounts();
    this.isAuthenticated = accounts && accounts.length > 0;
    this.msalInstance
      .handleRedirectPromise()
      .then((token: msal.AuthenticationResult | null) => {
        this.token = token;
        this.emit(loginEvent);
      })
      .catch((err: any) => {
        if (err.errorMessage && err.errorMessage.indexOf(forgotPasswordErrorCode) > -1) {
          this.resetPassword();
        } else {
          console.error(err);
        }
      });
  }

  public checkSignedIn(): Promise<void> {
    return new Promise(async (resolve, reject) => {
        await this.refreshTokenAsync();
        if (this.token?.idToken) {
          resolve();
        } else {
          reject();
        }
    });
  }

  public getToken() {
    return this.token;
  }

  public async refreshTokenAsync(): Promise<void> {
    const request = {
      account: this.msalInstance.getAllAccounts()[0],
      scopes: this.scopes,
    };
    try {
      this.token = await this.msalInstance.acquireTokenSilent(request);
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        this.msalInstance.acquireTokenRedirect(request).catch((error) => {
          console.error(error);
        });
      }
    }
  }

  private resetPassword() {
    try {
      this.msalInstance.loginRedirect({
        authority: `https://${process.env.VUE_APP_AZUREADB2C_TENANT_NAME}.b2clogin.com/` +
          `${process.env.VUE_APP_AZUREADB2C_TENANT_NAME}.onmicrosoft.com/` +
          `${process.env.VUE_APP_AZUREADB2C_RESETPASSWORD_POLICY_ID}`,
        scopes: this.scopes,
      });
    } catch (passwordResetError) {
      console.error(passwordResetError);
    }
  }

  public signIn() {
    try {
      const loginRequest: msal.RedirectRequest = { scopes: this.scopes };
      this.msalInstance.loginRedirect(loginRequest);
    } catch (err: any) {
      if (err.errorMessage && err.errorMessage.indexOf(forgotPasswordErrorCode) > -1) {
        this.resetPassword();
      } else {
        this.isAuthenticated = false;
      }
    }
  }

  public async signOut() {
    await this.msalInstance.logoutRedirect();
  }
}

const service = new AuthService();
service.setMaxListeners(5);

export default service;
