var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c("v-card-title", { attrs: { dark: "" } }, [_vm._v("Notifications")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-expansion-panels",
            _vm._l(_vm.notifications, function (alert, index) {
              return _c(
                "v-expansion-panel",
                { key: index, staticClass: "dense" },
                [
                  _c(
                    "v-expansion-panel-header",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "float-left mr-3",
                                  attrs: { size: "20", color: alert.color },
                                },
                                [
                                  alert.color === "success"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { size: "16", dark: "" } },
                                        [_vm._v("notification_important ")]
                                      )
                                    : _c(
                                        "v-icon",
                                        { attrs: { size: "16", dark: "" } },
                                        [_vm._v("error")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "inline-block float-left mt-1" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        alert.date.toLocaleDateString() +
                                          " " +
                                          alert.date.toLocaleTimeString()
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "inline-block float-left ml-3 mt-1",
                                },
                                [_vm._v(" " + _vm._s(alert.message) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-expansion-panel-content", [
                    alert.error
                      ? _c("pre", { staticClass: "scroll-x" }, [
                          _vm._v(
                            "              " +
                              _vm._s(_vm.getFormattedError(alert.error)) +
                              "\n          "
                          ),
                        ])
                      : _c("div", [
                          _vm._v("No Additional Information Available"),
                        ]),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("v-card-actions"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }