import { EnumObject } from '@/domainmodels/enums';

export interface classInt {
  prototype: Record<string, unknown>;
}

export class Utilities {
  disableAll(): void {
    const elements = document.querySelectorAll('*');
    for (let i = 0; i < elements.length; i++) {
      let style = elements[i].attributes.getNamedItem('style');
      if (style == null) style = document.createAttribute('style');
      style.value = (style.value ? style.value : '') + ' pointer-events: none;';
      elements[i].attributes.setNamedItem(style);
      const tabIndex = document.createAttribute('tabindex');
      tabIndex.value = '-1';
      elements[i].attributes.setNamedItem(tabIndex);
    }
    const inputs = document.querySelectorAll('input');
    for (let j = 0; j < inputs.length; j++) {
      inputs[j].disabled = true;
    }
    const buttons = document.querySelectorAll('button');
    for (let k = 0; k < buttons.length; k++) {
      buttons[k].disabled = true;
    }
  }

  sortObjectArray<T, K extends keyof T>(array: Array<T>, sortParam: K, desc?: boolean): Array<T> {
    const sortedArray = array.sort((a: T, b: T) => {
      let propA: T[K] | string = a[sortParam];
      let propB: T[K] | string = b[sortParam];
      propA = typeof propA === 'string' ? propA.toLowerCase() : propA;
      propB = typeof propB === 'string' ? propB.toLowerCase() : propB;
      if (propA < propB) {
        return -1;
      } else if (a[sortParam] > b[sortParam]) {
        return 1;
      } else {
        return 0;
      }
    });
    return desc ? sortedArray.reverse() : sortedArray;
  }

  sortArray<T>(array: Array<T>, desc?: boolean): T[] {
    const sortedArray = array.sort((a: T, b: T) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return desc ? sortedArray.reverse() : sortedArray;
  }

  deepClone<T>(inObject: T | T[] | classInt): T | T[] {
    let outObject, value;

    if (typeof inObject !== 'object' || inObject === null) {
      return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? ([] as T[]) : ({} as T);
    if (Array.isArray(inObject)) {
      outObject = [] as T[];
      for (let x = 0; x < inObject.length; x++) {
        value = inObject[x];
        outObject[x] = this.deepClone(value) as T;
      }
    } else {
      const proto = Object.getPrototypeOf(inObject);
      if (proto && proto.constructor) {
        outObject = new proto.constructor();
      }

      let k: keyof T;
      inObject = inObject as T;
      for (k in inObject) {
        value = inObject[k];
        outObject[k] = this.deepClone(value);
      }
    }

    return outObject;
  }

  enumToArray<T extends Record<number, string>>(enumToProcess: T): EnumObject[] {
    const enumArray = [] as EnumObject[];
    let k: keyof T;
    for (k in enumToProcess) {
      const keyVal = parseInt(k as string);
      if (!isNaN(keyVal)) {
        const val = enumToProcess[k].toString();
        enumArray.push(new EnumObject(keyVal, val));
      }
    }
    return enumArray;
  }

  arraysAreEqual<T>(array1: T[], array2: T[]): boolean {
    let equal = true;
    equal = equal && array1.length === array2.length;
    if (equal) {
      for (let i = 0; i < array1.length; i++) {
        if (typeof array1[i] === 'object') {
          equal = equal && this.objectsAreEqual(array1[i], array2[i]);
        } else {
          equal = equal && array1[i] === array2[i];
        }
      }
    }
    return equal;
  }

  objectsAreEqual<T>(object1: T, object2: T): boolean {
    let k: keyof T;
    let same = true;
    for (k in object1) {
      if (Array.isArray(object1[k])) {
        const array1 = [];
        for (const x in object1[k]) {
          array1.push(object1[k][x]);
        }
        const array2 = [];
        for (const x in object2[k]) {
          array2.push(object2[k][x]);
        }
        same = same && this.arraysAreEqual(array1, array2);
      } else if (typeof object1[k] === 'object') {
        same = same && this.objectsAreEqual(object1[k], object2[k]);
      } else {
        same = same && object1[k] === object2[k];
      }
    }
    return same;
  }

  formatJson(input: string): string {
    if (this.isJSON(input)) {
      const obj = JSON.parse(input);
      const output = JSON.stringify(obj, undefined, 4);
      return output;
    } else return 'No Additional Information Available';
  }

  isJSON(str: string): boolean {
    if (str) {
      if (/^\s*$/.test(str)) return false;
      str = str.replace(/\\(?:["\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
      str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']');
      str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
      return /^[\],:{}\s]*$/.test(str);
    } else {
      return false;
    }
  }

  distinct<T>(arrayIn: T[]): T[] {
    const distinctArray = [...new Set(arrayIn)];
    return distinctArray;
  }

  abbreviatedMonthName(monthNumber: number): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthNumber];
  }

  printDate(dateString: string): string {
    const date = new Date(dateString);
    const monthName = this.abbreviatedMonthName(date.getUTCMonth());
    const monthDay = date.getUTCDate().toString();
    const year = date.getFullYear().toString();
    return monthName + ' ' + monthDay + ', ' + year;
  }

  shortUTCDate(dateString: string): string {
    const dateObject = new Date(dateString);
    return dateObject.getUTCMonth() + 1 + '/' + dateObject.getUTCDate() + '/' + dateObject.getUTCFullYear();
  }

  createCSVLinkAndDownload(title: string, csvText: string): void {
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText);
    link.download = title;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  createExcelLinkAndDownload(title: string, base64String: string): void {
    const link = document.createElement('a');
    link.href = 'data:application/octet-stream;charset=utf-8;base64,' + base64String;
    link.download = title;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  formatCSVString(input: string): string {
    if (typeof input === 'undefined' || input === null || input === '') {
      return '';
    }
    return '"' + input.toString().replace(/"/g, '""') + '"';
  }
}

const utilities = new Utilities();
export default utilities;
