export default class PartyData {
  public name: string;
  public contactName: string;
  public contactPhone: string;
  public addressLines: string[];
  public cityName: string;
  public countrySubdivisionCode: string;
  public postalCode: string;
  public notes: string[];
  public newNote: string;

  constructor() {
    this.name = '';
    this.contactName = '';
    this.contactPhone = '';
    this.addressLines = [''] as string[];
    this.cityName = '';
    this.countrySubdivisionCode = '';
    this.postalCode = '';
    this.notes = [] as string[];
    this.newNote = '';
  }
}
