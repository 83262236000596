<template>
  <v-navigation-drawer
    dark
    color="primary"
    app
    v-model="navModel"
    :mini-variant="mini"
    hide-overlay
    fixed
    left
    stateless
    clipped
  >
    <div flat v-if="isAuthenticated && user.currentBranchId">
      <v-list class="pa-1">
        <v-list-item v-if="false" title="Dashboard" to="/dashboard">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>Dashboard</v-list-item-content>
        </v-list-item>
        <v-list-item title="Issues" to="/issues">
          <v-list-item-action>
            <v-icon>warning</v-icon>
          </v-list-item-action>
          <v-list-item-content>Issues</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!user.isCurrentSupplierIntegrated" title="Orders" to="/orders">
          <v-list-item-action>
            <v-icon>shopping_cart</v-icon>
          </v-list-item-action>
          <v-list-item-content>POs</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!user.isCurrentSupplierIntegrated" title="Requests" to="/requests">
          <v-list-item-action>
            <v-icon>input</v-icon>
          </v-list-item-action>
          <v-list-item-content>Requests</v-list-item-content>
        </v-list-item>
        <v-list-item title="Opportunities" to="/opportunities">
          <v-list-item-action>
            <v-icon>forward</v-icon>
          </v-list-item-action>
          <v-list-item-content>Opportunities</v-list-item-content>
        </v-list-item>
        <v-list-item title="Customers" to="/customers">
          <v-list-item-action>
            <v-icon>people</v-icon>
          </v-list-item-action>
          <v-list-item-content>Customers</v-list-item-content>
        </v-list-item>
        <v-list-item title="Jobs" to="/jobs">
          <v-list-item-action><v-icon v-if="mini">link</v-icon> </v-list-item-action>
          <v-list-item-content class="ml-3">Jobs</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="false" title="Agreements" to="/agreements" class="ml-3" dense>
          <v-list-item-action> </v-list-item-action>
          <v-list-item-content>Agreements</v-list-item-content>
        </v-list-item>
        <v-list-item title="Products">
          <v-list-item-action>
            <v-icon>list</v-icon>
          </v-list-item-action>
          <v-list-item-content>Products</v-list-item-content>
        </v-list-item>
        <v-list-item title="Vendor Catalog" to="/products/vendorcatalog">
          <v-list-item-action><v-icon v-if="mini">link</v-icon> </v-list-item-action>
          <v-list-item-content class="ml-3">Vendor Catalog</v-list-item-content>
        </v-list-item>
        <v-list-item title="Cross-References" to="/products/crossreferences">
          <v-list-item-action><v-icon v-if="mini">link</v-icon> </v-list-item-action>
          <v-list-item-content class="ml-3">Cross-References</v-list-item-content>
        </v-list-item>
        <v-list-item title="Mappings" to="/products/mappings">
          <v-list-item-action><v-icon v-if="mini">link</v-icon> </v-list-item-action>
          <v-list-item-content class="ml-3">Mappings</v-list-item-content>
        </v-list-item>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-if="user.isAdmin" to="/billing" v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-icon>credit_card</v-icon>
              </v-list-item-action>
              <v-list-item-content>Billing (admin)</v-list-item-content>
            </v-list-item>
          </template>
          <span>Shows all sent Purchase Orders</span>
        </v-tooltip>
        <v-list-item title="Users" to="/users">
          <v-list-item-action><v-icon>people</v-icon></v-list-item-action>
          <v-list-item-content>Users</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.isAdmin || user.isGlobalAdmin" title="Activity History" to="/activityhistory">
          <v-list-item-action><v-icon>history</v-icon></v-list-item-action>
          <v-list-item-content>Activity History</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.isAdmin" title="Settings (admin)" to="/settings">
          <v-list-item-action>
            <v-icon>tune</v-icon>
          </v-list-item-action>
          <v-list-item-content>Settings (admin)</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.isAdmin" title="Branches (admin)" to="/branches">
          <v-list-item-action><v-icon v-if="mini">business</v-icon> </v-list-item-action>
          <v-list-item-content class="ml-3">Branches (admin)</v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <template v-slot:append v-if="isAuthenticated">
      <hr class="mx-3" />
      <v-list>
        <v-list-item title="Account" to="account">
          <v-list-item-action>
            <v-icon outlined>account_circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>Account</v-list-item-content>
        </v-list-item>
        <v-list-item title="Help" to="/knowledge-base">
          <v-list-item-action><v-icon>help_outline</v-icon></v-list-item-action>
          <v-list-item-content>Help</v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:append v-else>
      <hr class="mx-3" />
      <v-list>
        <v-list-item title="Log In" @click="$emit('login')">
          <v-list-item-action>
            <v-icon>input</v-icon>
          </v-list-item-action>
          <v-list-item-content>Log In</v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import { VueConstructor } from 'vue';
import { mapState } from 'vuex';

const LeftNav = (Vue as VueConstructor<Vue>).extend({
  props: {
    mini: { type: Boolean },
    drawer: { type: Boolean },
    xsNavVisible: { type: Boolean },
    isAuthenticated: { type: Boolean },
  },
  components: {},
  computed: {
    ...mapState('userStore', {
      user: 'user',
    }),
    navModel: {
      get(): boolean {
        if (this.$vuetify.breakpoint.xs) {
          return this.xsNavVisible;
        } else {
          return this.drawer;
        }
      },
      set(value: boolean) {
        this.$emit('nav-model', value);
      },
    },
  },
});
export default LeftNav;
</script>
