<template>
  <v-card>
    <v-card-title dark>Notifications</v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="(alert, index) in notifications" :key="index" class="dense">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="12">
                <v-avatar size="20" :color="alert.color" class="float-left mr-3">
                  <v-icon size="16" dark v-if="alert.color === 'success'">notification_important </v-icon>
                  <v-icon size="16" v-else dark>error</v-icon>
                </v-avatar>
                <div class="inline-block float-left mt-1">
                  {{ alert.date.toLocaleDateString() + ' ' + alert.date.toLocaleTimeString() }}
                </div>
                <div class="inline-block float-left ml-3 mt-1">
                  {{ alert.message }}
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <pre v-if="alert.error" class="scroll-x">
                {{ getFormattedError(alert.error) }}
            </pre>
            <div v-else>No Additional Information Available</div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Alert from '@/eventbus/alert';
import { AxiosError } from 'axios';
import Vue, { PropType } from 'vue';
import { VueConstructor } from 'vue/types/vue';

const NotificationPanel = (Vue as VueConstructor).extend({
  props: {
    notifications: {
      type: Array as PropType<Alert[]>,
      required: true,
    },
  },

  methods: {
    getFormattedError(error: AxiosError) {
      const jsonString = JSON.stringify(error);
      return this.$utilities.formatJson(jsonString);
    },
  },
});

export default NotificationPanel;
</script>

<style lang="scss">
.scroll-x {
  overflow-x: scroll;
}
</style>
