import { EnumObject } from './enums';

export default class UserSettingsModel {
  public availableSuppliers: EnumObject[];
  public currencyCode: string;
  public currentBranchId: string;
  public currentCompanyId: string;
  public currentSupplierId: string;
  public isCurrentSupplierIntegrated: boolean;
  public language: string;
  public localeCode: string;
  public timeZone: string;
  public trialExpiration: Date | null;
  public userId: string;

  constructor() {
    this.availableSuppliers = [];
    this.currencyCode = '';
    this.currentBranchId = '';
    this.currentCompanyId = '';
    this.currentSupplierId = '';
    this.isCurrentSupplierIntegrated = false;
    this.language = '';
    this.localeCode = '';
    this.timeZone = '';
    this.trialExpiration = null;
    this.userId = '';
  }
}
