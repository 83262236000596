import { AxiosError } from 'axios';

export default class Alert {
  public message: string;
  public color: string;
  public timeout: number;
  public active: boolean;
  public showToast: boolean;
  public error?: AxiosError;
  public date: Date;

  constructor() {
    this.message = '';
    this.color = 'success';
    this.timeout = 0;
    this.active = true;
    this.showToast = false;
    this.error = undefined;
    this.date = new Date();
  }
}
