var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        dark: "",
        color: "primary",
        app: "",
        "mini-variant": _vm.mini,
        "hide-overlay": "",
        fixed: "",
        left: "",
        stateless: "",
        clipped: "",
      },
      scopedSlots: _vm._u(
        [
          _vm.isAuthenticated
            ? {
                key: "append",
                fn: function () {
                  return [
                    _c("hr", { staticClass: "mx-3" }),
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { attrs: { title: "Account", to: "account" } },
                          [
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-icon", { attrs: { outlined: "" } }, [
                                  _vm._v("account_circle"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-content", [_vm._v("Account")]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { attrs: { title: "Help", to: "/knowledge-base" } },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("help_outline")])],
                              1
                            ),
                            _c("v-list-item-content", [_vm._v("Help")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : {
                key: "append",
                fn: function () {
                  return [
                    _c("hr", { staticClass: "mx-3" }),
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            attrs: { title: "Log In" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("login")
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("input")])],
                              1
                            ),
                            _c("v-list-item-content", [_vm._v("Log In")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
        ],
        null,
        true
      ),
      model: {
        value: _vm.navModel,
        callback: function ($$v) {
          _vm.navModel = $$v
        },
        expression: "navModel",
      },
    },
    [
      _vm.isAuthenticated && _vm.user.currentBranchId
        ? _c(
            "div",
            { attrs: { flat: "" } },
            [
              _c(
                "v-list",
                { staticClass: "pa-1" },
                [
                  false
                    ? _c(
                        "v-list-item",
                        { attrs: { title: "Dashboard", to: "/dashboard" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("home")])],
                            1
                          ),
                          _c("v-list-item-content", [_vm._v("Dashboard")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { attrs: { title: "Issues", to: "/issues" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("warning")])],
                        1
                      ),
                      _c("v-list-item-content", [_vm._v("Issues")]),
                    ],
                    1
                  ),
                  !_vm.user.isCurrentSupplierIntegrated
                    ? _c(
                        "v-list-item",
                        { attrs: { title: "Orders", to: "/orders" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("shopping_cart")])],
                            1
                          ),
                          _c("v-list-item-content", [_vm._v("POs")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.user.isCurrentSupplierIntegrated
                    ? _c(
                        "v-list-item",
                        { attrs: { title: "Requests", to: "/requests" } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("input")])],
                            1
                          ),
                          _c("v-list-item-content", [_vm._v("Requests")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { attrs: { title: "Opportunities", to: "/opportunities" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("forward")])],
                        1
                      ),
                      _c("v-list-item-content", [_vm._v("Opportunities")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { title: "Customers", to: "/customers" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("people")])],
                        1
                      ),
                      _c("v-list-item-content", [_vm._v("Customers")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { title: "Jobs", to: "/jobs" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_vm.mini ? _c("v-icon", [_vm._v("link")]) : _vm._e()],
                        1
                      ),
                      _c("v-list-item-content", { staticClass: "ml-3" }, [
                        _vm._v("Jobs"),
                      ]),
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "ml-3",
                          attrs: {
                            title: "Agreements",
                            to: "/agreements",
                            dense: "",
                          },
                        },
                        [
                          _c("v-list-item-action"),
                          _c("v-list-item-content", [_vm._v("Agreements")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { attrs: { title: "Products" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("list")])],
                        1
                      ),
                      _c("v-list-item-content", [_vm._v("Products")]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        title: "Vendor Catalog",
                        to: "/products/vendorcatalog",
                      },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_vm.mini ? _c("v-icon", [_vm._v("link")]) : _vm._e()],
                        1
                      ),
                      _c("v-list-item-content", { staticClass: "ml-3" }, [
                        _vm._v("Vendor Catalog"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        title: "Cross-References",
                        to: "/products/crossreferences",
                      },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_vm.mini ? _c("v-icon", [_vm._v("link")]) : _vm._e()],
                        1
                      ),
                      _c("v-list-item-content", { staticClass: "ml-3" }, [
                        _vm._v("Cross-References"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { title: "Mappings", to: "/products/mappings" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_vm.mini ? _c("v-icon", [_vm._v("link")]) : _vm._e()],
                        1
                      ),
                      _c("v-list-item-content", { staticClass: "ml-3" }, [
                        _vm._v("Mappings"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _vm.user.isAdmin
                                  ? _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { to: "/billing" } },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-icon", [
                                              _vm._v("credit_card"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-content", [
                                          _vm._v("Billing (admin)"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1482677412
                      ),
                    },
                    [_c("span", [_vm._v("Shows all sent Purchase Orders")])]
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { title: "Users", to: "/users" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("people")])],
                        1
                      ),
                      _c("v-list-item-content", [_vm._v("Users")]),
                    ],
                    1
                  ),
                  _vm.user.isAdmin || _vm.user.isGlobalAdmin
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            title: "Activity History",
                            to: "/activityhistory",
                          },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("history")])],
                            1
                          ),
                          _c("v-list-item-content", [
                            _vm._v("Activity History"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.isAdmin
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { title: "Settings (admin)", to: "/settings" },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("tune")])],
                            1
                          ),
                          _c("v-list-item-content", [
                            _vm._v("Settings (admin)"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.isAdmin
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { title: "Branches (admin)", to: "/branches" },
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _vm.mini
                                ? _c("v-icon", [_vm._v("business")])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-list-item-content", { staticClass: "ml-3" }, [
                            _vm._v("Branches (admin)"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }