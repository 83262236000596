import BranchModel from '@/domainmodels/branchModel';
import JobAccountLink from '@/joblinking/jobAccountLink';
import PartyData from '@/requests/partyData';
import PurchasingRequest from '@/requests/purchasingRequest';
import SupplierAvailability from '@/requests/supplierAvailability';
import SupplierPurchaseOrder from '@/requests/supplierPurchaseOrder';
import SupplierQuote from '@/requests/supplierQuote';
import SupplierResponseBase from '@/requests/supplierResponseBase';
import SupplierShipment from '@/domainmodels/supplierShipment';
import { ActionContext, Module } from 'vuex';
import { RootState } from '../rootState';
import RequestState from './requestState';

export const requestStore: Module<RequestState, RootState> = {
  namespaced: true,
  state: new RequestState(),
  mutations: {
    updateSelectedRequest(state: RequestState, request: PurchasingRequest): void {
      state.selectedRequest = request;
    },
    updateRespondMode(state: RequestState, respondMode: boolean): void {
      state.respondMode = respondMode;
    },
    updateShowShipping(state: RequestState, value: boolean): void {
      state.showShipping = value;
    },
    updateSelectedResponse(
      state: RequestState,
      response: SupplierQuote | SupplierAvailability | SupplierPurchaseOrder
    ): void {
      state.selectedResponse = null; // Trigger Vue update
      state.selectedResponse = response;
      if (state.selectedRequest && state.selectedResponse) {
        for (let i = 0; i < state.selectedResponse.lineItems.length; i++) {
          const li = state.selectedResponse.lineItems[i];
          const match = state.selectedRequest.lineItems.find((_li) => li.lineNumber == _li.lineNumber);
          li.allowAlternates = match ? match.allowAlternates : false;
        }
      }
    },
    updateSelectedShipment(state: RequestState, shipment: SupplierShipment | undefined): void {
      state.selectedShipment = shipment;
    },
    updateShippingReadOnly(state: RequestState, value: boolean): void {
      state.shippingReadOnly = value;
    },
    updateJobLinkInfo(state: RequestState, jobLinkInfo: JobAccountLink): void {
      state.jobLinkInfo = jobLinkInfo;
    },
    addShipment(state: RequestState, shipment: SupplierShipment): void {
      if (state.selectedRequest && state.selectedRequest.shipments) {
        state.selectedRequest.shipments.push(shipment);
      }
    },
    removeShipment(state: RequestState, shipment: SupplierShipment): void {
      if (state.selectedRequest) {
        state.selectedRequest.shipments = state.selectedRequest.shipments.filter((x) => x.id !== shipment.id);
      }
    },
    updateCheckResponseValid(state: RequestState, check: boolean): void {
      state.checkResponseValid = check;
    },
  },

  actions: {
    setSelectedRequest(
      { commit }: ActionContext<RequestState, RootState>,
      request: PurchasingRequest | undefined
    ): void {
      commit('updateSelectedRequest', request);
    },
    setRespondMode({ commit }: ActionContext<RequestState, RootState>, respondMode: boolean): void {
      commit('updateRespondMode', respondMode);
    },
    setSelectedResponse({ commit }: ActionContext<RequestState, RootState>, response: SupplierResponseBase): void {
      commit('updateSelectedResponse', response);
    },
    setShowShipping({ commit }: ActionContext<RequestState, RootState>, value: boolean): void {
      commit('updateShowShipping', value);
    },
    setSelectedShipment({ commit }: ActionContext<RequestState, RootState>, shipment: SupplierShipment): void {
      commit('updateSelectedShipment', shipment);
    },
    setShippingReadOnly({ commit }: ActionContext<RequestState, RootState>, value: boolean): void {
      commit('updateShippingReadOnly', value);
    },
    setJobLinkInfo({ commit }: ActionContext<RequestState, RootState>, jobLinkInfo: JobAccountLink): void {
      commit('updateJobLinkInfo', jobLinkInfo);
    },
    setBranchInfo({ state, commit }: ActionContext<RequestState, RootState>, selectedBranch: BranchModel): void {
      if (state.selectedShipment) {
        state.selectedShipment.shipFromParty.branchIdentifier = selectedBranch.branchIdentifier;
        state.selectedShipment.shipFromParty.warehouseName =
          selectedBranch.name + ' (' + selectedBranch.branchIdentifier + ')';
        const fulfillmentParty = new PartyData();
        fulfillmentParty.contactName = state.jobLinkInfo.displayName;
        fulfillmentParty.contactPhone = selectedBranch.phone;
        fulfillmentParty.addressLines = [selectedBranch.address1];
        fulfillmentParty.cityName = selectedBranch.city;
        fulfillmentParty.countrySubdivisionCode = selectedBranch.stateOrProvinceEnumName;
        fulfillmentParty.postalCode = selectedBranch.postalCode;
        state.selectedShipment.shipFromParty.fulfillmentParty = fulfillmentParty;
        commit('updateSelectedShipment', state.selectedShipment);
      }
    },
    addShipmentToRequest({ commit }: ActionContext<RequestState, RootState>, shipment: SupplierShipment): void {
      commit('addShipment', shipment);
    },
    removeShipmentFromRequest({ commit }: ActionContext<RequestState, RootState>, shipment: SupplierShipment): void {
      commit('removeShipment', shipment);
    },
    setCheckResponseValid({ commit }: ActionContext<RequestState, RootState>, check: boolean): void {
      commit('updateCheckResponseValid', check);
    },
  },
};
