import Customer from '@/customers/customer';
import Vue from 'vue';
import Vuex, { ActionContext, Module } from 'vuex';
import { AxiosInstance, AxiosResponse } from 'axios';
import UserState from './userState';
import UserSettingsModel from '@/domainmodels/userSettingsModel';
import { RootState } from '../rootState';
import BranchModel from '@/domainmodels/branchModel';
import VueInstance, { bus } from '@/main';
import { IdTokenClaims } from '@/auth/idTokenClaims';
Vue.use(Vuex);

const supplierApi = process.env.VUE_APP_BASE_URL! + process.env.VUE_APP_SUPPLIER_API;
export const userStore: Module<UserState, RootState> = {
  namespaced: true,
  state: new UserState(),
  mutations: {
    updateBranches(state: UserState, branches: BranchModel[]): void {
      state.branches = branches;
    },
    updateCustomers(state: UserState, customers: Customer[]): void {
      state.customers = customers;
    },
    updateProfile(state: UserState, profile: IdTokenClaims): void {
      state.profile = profile;
    },
    updateSelectedCustomer(state: UserState, customerId: string): void {
      state.selectedCustomer = customerId;
    },
    updateUser(state: UserState, user: UserSettingsModel): void {
      state.user = user;
      VueInstance.$http.defaults.headers.common.UserId = user.userId;
      VueInstance.$httpCache.defaults.headers.common.UserId = user.userId;
      VueInstance.$httpCacheClient.defaults.headers.common.UserId = user.userId;
      VueInstance.$http.defaults.headers.common.SupplierId = user.currentSupplierId;
      VueInstance.$httpCache.defaults.headers.common.SupplierId = user.currentSupplierId;
      VueInstance.$httpCacheClient.defaults.headers.common.SupplierId = user.currentSupplierId;
    },
  },
  actions: {
    getCustomers({ state, commit }: ActionContext<UserState, RootState>): void {
      const $http: AxiosInstance = VueInstance.$http;
      $http
        .get(supplierApi + '/customer')
        .then((result) => {
          commit('updateCustomers', result.data);
          if (!state.selectedCustomer && state.customers.length > 0) {
            state.selectedCustomer = state.customers[0].id;
          }
        })
        .catch((error: string) => {
          bus.$emit('alert', 'Get customers failed - ' + error, 'error', 6000);
        });
    },

    async getUserSettings(
      { state, dispatch, commit }: ActionContext<UserState, RootState>,
      callback: () => void
    ): Promise<void> {
      const $http: AxiosInstance = VueInstance.$http;
      $http
        .get(supplierApi + '/usersettings')
        .then((result: AxiosResponse<UserSettingsModel>) => {
          result.data.localeCode =
            navigator.language.indexOf('-') > -1 ? navigator.language.split('-')[1] : navigator.language.split('_')[1];
          result.data.currencyCode = 'USD';
          result.data.language = navigator.language;
          commit('updateUser', result.data);
          dispatch('getCustomers').then(() => {
            state.selectedCustomer = result.data.currentCompanyId;
          });
          dispatch('getNavBranches');
          if (callback) {
            callback();
          }
        })
        .catch((error: string) => {
          bus.$emit('alert', 'Get user settings failed - ' + error, 'error', 6000);
        });
    },

    getNavBranches({ commit, dispatch }: ActionContext<UserState, RootState>): void {
      const $http: AxiosInstance = VueInstance.$http;
      $http
        .get(supplierApi + '/navbranch')
        .then((result) => {
          commit('updateBranches', result.data);
          dispatch('checkCurrentBranch', result.data);
        })
        .catch((error: string) => {
          bus.$emit('alert', 'Get branches failed - ' + error, 'error', 6000);
        });
    },

    setSelectedCustomer({ commit, dispatch, state }: ActionContext<UserState, RootState>, customerId: string): void {
      state.user.currentCompanyId = customerId;
      dispatch('updateUserSettings', state.user);
      commit('updateSelectedCustomer', customerId);
    },

    updateSupplierId({ dispatch, state }: ActionContext<UserState, RootState>, supplierId: string): void {
      state.user.currentSupplierId = supplierId;
      dispatch('updateUserSettings', state.user).then(() => {
        dispatch('getCustomers');
        dispatch('getNavBranches');
      });
    },

    updateTimeZone({ dispatch, state }: ActionContext<UserState, RootState>, timeZone: string): void {
      state.user.timeZone = timeZone;
      dispatch('updateUserSettings', state.user);
    },

    updateBranchId({ dispatch, state }: ActionContext<UserState, RootState>, branchId: string): void {
      if (state.user.currentBranchId !== branchId) {
        state.user.currentBranchId = branchId;
        dispatch('updateUserSettings', state.user).then(() => {
          dispatch('getCustomers');
          dispatch('getNavBranches');
        });
      }
    },

    updateUserSettings({ commit, state }: ActionContext<UserState, RootState>, user: UserSettingsModel): void {
      VueInstance.$http({
        url: supplierApi + '/usersettings',
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        data: user,
      })
        .then((result) => {
          user = result.data;
          commit('updateUser', user);
          state.selectedCustomer = result.data.currentCompanyId;
        })
        .catch((error: string) => {
          bus.$emit('alert', 'Update user settings failed - ' + error, 'error', 6000);
        });
    },

    checkCurrentBranch({ dispatch, state }: ActionContext<UserState, RootState>, branches: BranchModel[]): void {
      let currentBranch = branches.find((x) => x.id === state.user.currentBranchId);
      if (!currentBranch) {
        currentBranch = branches[0];
      }
      dispatch('updateBranchId', currentBranch.id);
    },

    refreshBranches({ dispatch }: ActionContext<UserState, RootState>): void {
      dispatch('getNavBranches');
    },
  },
  getters: {
    profile: (state: UserState): IdTokenClaims => {
      return state.profile as IdTokenClaims;
    },
    user: (state: UserState): UserSettingsModel => {
      return state.user;
    },
  },
};
