import Vue from 'vue';
import Vuex, { ActionContext, Module } from 'vuex';
import { AxiosInstance, AxiosResponse } from 'axios';
import { RootState } from '../rootState';
import VueInstance, { bus } from '@/main';
import EnumState from './enumState';
import { EnumObject } from '@/domainmodels/enums';
Vue.use(Vuex);

const supplierApi = process.env.VUE_APP_BASE_URL! + process.env.VUE_APP_SUPPLIER_API;
export const enumStore: Module<EnumState, RootState> = {
  namespaced: true,
  state: new EnumState(),
  mutations: {
    updateUnitOfMeasures(state: EnumState, unitOfMeasures: EnumObject[]): void {
      state.unitOfMeasures = unitOfMeasures;
    },
    updatePurchasingRequestTypes(state: EnumState, purchasingRequestTypes: EnumObject[]): void {
      state.purchasingRequestTypes = purchasingRequestTypes;
    },
  },
  actions: {
    async getUnitOfMeasures(
      { state, commit }: ActionContext<EnumState, RootState>,
      callback: () => void
    ): Promise<void> {
      if (state.unitOfMeasures.length === 0) {
        const $http: AxiosInstance = VueInstance.$http;
        $http
          .get(supplierApi + '/enum?enumTypeName=MarketPlaceUnitOfMeasure')
          .then((result: AxiosResponse<EnumObject[]>) => {
            commit('updateUnitOfMeasures', result.data);
            if (callback) {
              callback();
            }
          })
          .catch((error) => {
            bus.$emit('alert', 'Get unit of measures failed', 'error', 6000, error);
          });
      }
    },

    initializePurchasingRequestTypes(
      { commit }: ActionContext<EnumState, RootState>,
      purchasingRequestTypes: EnumObject[]
    ): void {
      commit('updatePurchasingRequestTypes', purchasingRequestTypes);
    },
  },
  getters: {
    getRequestTypeName: (state: EnumState) => (requestType: number) => {
      const enumObject = state.purchasingRequestTypes.find((x) => x.value === requestType);
      return enumObject?.text;
    },
  },
};
