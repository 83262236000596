import _Vue from 'vue';
import Axios, { AxiosAdapter, AxiosRequestConfig, AxiosInstance } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

const adapter = Axios.defaults.adapter as AxiosAdapter;
const config: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  adapter: cacheAdapterEnhancer(adapter, { enabledByDefault: false }),
};

const cacheConfig: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  adapter: cacheAdapterEnhancer(adapter, { enabledByDefault: true }),
};

const cacheConfigClient: AxiosRequestConfig = {
  baseURL: '', // will call CDN rather than API
  adapter: cacheAdapterEnhancer(adapter, { enabledByDefault: true }),
};

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function AxiosPlugin(Vue: typeof _Vue): void {
  // do stuff with options

  Vue.prototype.$http = Axios.create(config);
  Vue.prototype.$httpCache = Axios.create(cacheConfig);
  Vue.prototype.$httpCacheClient = Axios.create(cacheConfigClient) as AxiosInstance;
  Vue.prototype.$openApi = process.env.VUE_APP_OPEN_API;
  Vue.prototype.$supplierApi = process.env.VUE_APP_SUPPLIER_API;
}

export class AxiosPluginOptions {}
