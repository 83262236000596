import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VueInstance, { bus } from '@/main';
import { userStore } from '@/store/user/userStore';
import { enumStore } from '@/store/enum/enumStore';
import { requestStore } from '@/store/request/requestStore';
import { RootState } from './store/rootState';
import { AxiosResponse } from 'axios';

Vue.use(Vuex);

const supplierApi = process.env.VUE_APP_BASE_URL! + process.env.VUE_APP_SUPPLIER_API;

const store: StoreOptions<RootState> = {
  state: {
    version: '1',
  },
  modules: {
    userStore,
    enumStore,
    requestStore,
  },
  mutations: {
    updateVersion(state: RootState, version: string) {
      state.version = version;
    },
  },
  actions: {
    getVersion({ commit }) {
      VueInstance.$http
        .get(supplierApi + '/version')
        .then((result: AxiosResponse<string>) => {
          commit('updateVersion', result.data);
        })
        .catch((error: string) => {
          bus.$emit('alert', 'Get version failed - ' + error, 'error', 6000);
        });
    },
  },
};
export default new Vuex.Store<RootState>(store);
