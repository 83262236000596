var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "bg-warehouse" },
    [
      _c(
        "v-flex",
        {
          staticClass: "full-width",
          attrs: { "align-self-center": "", grow: "1" },
        },
        [
          _c(
            "v-card",
            {
              staticClass:
                "center-dialog align-center justify-center bg-white ph-2",
            },
            [
              _c(
                "v-card-title",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "1" } }),
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-img", {
                            attrs: {
                              height: "85px",
                              "aspect-ratio": "4.45 ",
                              src: require("@/assets/GTPConnect.png"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "1" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c("h1", { staticClass: "primary--text" }, [
                    _c("i", [_vm._v("SOAR Above the Disconnect")]),
                  ]),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }),
                      _c(
                        "v-col",
                        { staticClass: "text-left", attrs: { cols: "8" } },
                        [
                          _c(
                            "h3",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "primary", dark: "" },
                                },
                                [_vm._v("check_circle")]
                              ),
                              _vm._v("Strengthening Partnerships"),
                            ],
                            1
                          ),
                          _c(
                            "h3",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "primary", dark: "" },
                                },
                                [_vm._v("check_circle")]
                              ),
                              _vm._v("On Demand Communications"),
                            ],
                            1
                          ),
                          _c(
                            "h3",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "primary", dark: "" },
                                },
                                [_vm._v("check_circle")]
                              ),
                              _vm._v("Access to Information"),
                            ],
                            1
                          ),
                          _c(
                            "h3",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "primary", dark: "" },
                                },
                                [_vm._v("check_circle")]
                              ),
                              _vm._v("Redefining Material Logistics "),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("v-col", { attrs: { cols: "2" } }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "4" } }),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("login")
                                },
                              },
                            },
                            [_vm._v("Login")]
                          ),
                          _c("p", { staticClass: "mt-1" }, [
                            _c(
                              "a",
                              { attrs: { href: "mailto:sales@gogtp.com" } },
                              [_vm._v("Request an account")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "4" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }