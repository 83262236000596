import { EnumObject } from '@/domainmodels/enums';

export default class EnumState {
  public unitOfMeasures: EnumObject[];
  public purchasingRequestTypes: EnumObject[];

  constructor() {
    this.unitOfMeasures = [] as EnumObject[];
    this.purchasingRequestTypes = [] as EnumObject[];
  }
}
