<template>
  <v-app id="app">
    <v-layout>
      <top-nav
        v-if="isAuthenticated"
        :isAuthenticated="isAuthenticated"
        :notification-badge="busAlerts.length || 0"
        @toggle-nav="xsNavVisible = !xsNavVisible"
        @toggle-notifications="showNotificationPanel = !showNotificationPanel"
        @toggle-mini="mini = !mini"
        @login="login()"
        @logout="logout()"
      ></top-nav>
      <left-nav
        v-if="isAuthenticated"
        :mini="mini"
        :drawer="drawer"
        :xsNavVisible="xsNavVisible"
        :isAuthenticated="isAuthenticated"
        left
        @toggle-mini="mini = !mini"
        @login="login()"
      ></left-nav>
      <v-main app class="offwhite">
        <v-container fluid> <router-view @login="login()" /> </v-container>
        <v-snackbar
          v-for="alert in toastAlerts"
          :key="alert.key"
          v-model="alert.active"
          top
          vertical
          multi-line
          :color="alert.color"
          :timeout="alert.timeout"
          >{{ alert.message }}
          <v-btn color="white" text @click="removeAlert(alert)">Dismiss</v-btn>
        </v-snackbar>
        <v-dialog
          content-class="top-right"
          v-if="showNotificationPanel"
          v-model="showNotificationPanel"
          max-width="800"
        >
          <notification-panel :notifications="busAlerts"></notification-panel>
        </v-dialog>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script lang="ts">
interface AppInterface {
  busAlerts: Alert[];
  expanded: boolean;
  isAuthenticated: boolean;
  getUserSettings: any;
  getVersion: any;
  handleAlert: any;
  mini: boolean;
  signedIn: any;
  toastAlerts: Alert[];
  updateProfile: any;
}

import Alert from '@/eventbus/alert';
import { AxiosError } from 'axios';
import bus from '@/main';
import { IdTokenClaims } from './auth/idTokenClaims';
import LeftNav from '@/navigation/LeftNav.vue';
import { mapMutations, mapActions, mapState } from 'vuex';
import NotificationPanel from '@/notifications/NotificationPanel.vue';
import ResponseError from './domainmodels/responseError';
import TopNav from '@/navigation/TopNav.vue';
import Vue, { VueConstructor } from 'vue';
import VueApexCharts from 'vue-apexcharts';

Vue.component('apexchart', VueApexCharts);

export default (Vue as VueConstructor<Vue & AppInterface>).extend({
  name: 'appVue',
  components: {
    LeftNav,
    TopNav,
    NotificationPanel,
  },

  async created() {
    this.$auth.addListener('loginEvent', this.signedIn);
    this.$auth.checkSignedIn().then(
      () => {
        this.signedIn();
      },
      () => {
        if (!this.isLoginOrCallback) {
          this.$router.push({ path: '/login' });
        }
      },
    );  
    Vue.nextTick(() => {
      bus.$on('alert', (message: string, color?: string, timeout?: number, error?: AxiosError<ResponseError>, showToast?: boolean) =>
        this.handleAlert(message, color, timeout, error, showToast));
    });
  },

  data() {
    return {
      busAlerts: [] as Alert[],
      drawer: true,
      expanded: true,
      isAuthenticated: false,
      menuVisible: false,
      mini: false,
      showNotificationPanel: false,
      showTrialDisable: false,
      toastAlerts: [] as Alert[],
      xsNavVisible: false,
    };
  },
  computed: {
    ...mapState('userStore', {
      user: 'user',
    }),

    isLoginOrCallback() {
      return this.$route.path.startsWith('/login') || this.$route.path.startsWith('/callback');
    },
  },
  watch: {
    'user.userId': 'addUserIdHeader',
    'user.currentSupplierId': 'addSupplierIdHeader',
    $route(to) {
      if (this.user.trialExpiration && new Date(this.user.trialExpiration).getTime() < new Date().getTime()) {
        this.$utilities.disableAll();
        if (to.path != '/subscription') {
          this.$router
            .push({
              path: '/subscription',
            })
            .catch();
        }
      }
    },
  },
  methods: {
    ...mapMutations('userStore', ['updateProfile']),
    ...mapActions('userStore', ['getUserSettings', 'getBranches']),
    ...mapActions(['getVersion']),

    addUserIdHeader() {
      this.$http.defaults.headers.common.UserId = this.user.userId;
      this.$httpCache.defaults.headers.common.UserId = this.user.userId;
      this.$httpCacheClient.defaults.headers.common.UserId = this.user.userId;
    },

    addSupplierIdHeader() {
      this.$http.defaults.headers.common.SupplierId = this.user.currentSupplierId;
      this.$httpCache.defaults.headers.common.SupplierId = this.user.currentSupplierId;
      this.$httpCacheClient.defaults.headers.common.SupplierId = this.user.currentSupplierId;
    },

    handleAlert(message: string, color?: string, timeout?: number, error?: AxiosError, showToast?: boolean) {
      const alert = new Alert();
      alert.showToast = showToast || false;
      alert.message = message;
      alert.error = error;
      const existingAlert = this.toastAlerts.find((x) => x.message.indexOf(alert.message) > -1);
      if (!existingAlert) {
        if (error) {
          if (typeof error === 'string') {
            alert.message = alert.message + ' ' + error;
          } else {
            if (
              error.response !== null &&
              error.response !== undefined &&
              error.response.data !== null &&
              error.response.data !== undefined &&
              typeof error.response.data === 'string'
            ) {
              // check general server errors
              alert.message = alert.message + ' ' + error.response.data;
            } else if (
              error.response !== null &&
              error.response !== undefined &&
              error.response.data !== null &&
              error.response.data !== undefined &&
              error.response.data.errors !== null
            ) {
              if (
                error.response?.data.errors &&
                error.response.data.errors.DisplayName !== null &&
                error.response.data.errors.DisplayName !== undefined
              ) {
                // check for problem details object produced by built in .Net Core >3 model validation.
                error.response.data.errors.DisplayName.forEach((element: string) => {
                  alert.message = alert.message + ' ' + element;
                });
              } else {
                for (var key in error.response.data.errors) {
                  alert.message = alert.message + ' ' + error.response.data.errors[key];
                }
              }
            }
          }
        }

        if (color) {
          alert.color = color;
        }
        if (timeout) {
          alert.timeout = timeout;
        }
        if (alert.showToast) {
          this.toastAlerts.push(alert);
        }
        this.busAlerts.push(alert);
        if (alert.timeout > 0) {
          setTimeout(() => {
            this.toastAlerts = this.toastAlerts.filter((x) => {
              return x !== alert;
            });
          }, alert.timeout);
        }
      }
    },

    login() {
      this.$auth.signIn();
    },

    logout() {
      this.$auth.signOut();
    },

    removeAlert(alert: Alert): void {
      this.busAlerts = this.busAlerts.filter((x) => x !== alert);
    },

    async signedIn() {
      let token = this.$auth.getToken();
      if (!token) return;
      let idTokenClaims = token.idTokenClaims as IdTokenClaims;
      if (!idTokenClaims) return;

      this.isAuthenticated = true;
      this.mini = !this.isAuthenticated;
      
      this.$http.defaults.headers.common = {
        Authorization: `bearer ${token.idToken}`,
        AzureADId: idTokenClaims.sub,
        //@ts-ignore
        'app-id': process.env.VUE_APP_OPEN_API_ID
      };

      this.updateProfile(idTokenClaims);
      await this.getUserSettings(() => {
        if (this.user.currentBranchId) {
          if (this.$route.path.startsWith('/login')) {
            this.$router.push({ path: '/orders' });
          }
        } else {
          this.$router.push({
            path: '/error',
          });
        }
      });
      this.getVersion(this.$http);
    },

    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },

  destroyed() {
    this.$auth.removeListener('loginEvent', this.signedIn);
  },
});
</script>

<style lang="scss">
.row {
  margin: 0px -12px !important;
}
.map {
  height: 40vh;
  max-height: 500px;
  z-index: 0;
}

.theme--light.v-expansion-panels.left-icon {
  .no-icon {
    .v-expansion-panel-header__icon {
      display: none;
    }
  }
  .v-expansion-panel-header {
    .v-expansion-panel-header__icon {
      position: absolute;
      left: 4px;
      top: 25%;
      color: var(--v-primary-base);
      .v-icon {
        color: var(--v-primary-base);
      }
    }
  }
  .v-expansion-panel-header {
    padding-left: 44px;
  }
}

.dense {
  .v-expansion-panel-header {
    padding: 8px 32px;
    min-height: 24px;
  }
  .col {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.v-data-table .sticky-header {
  position: sticky;
  top: 64px;
  z-index: 2;
}

.v-data-table .v-data-table__wrapper {
  overflow: unset;
}
</style>
