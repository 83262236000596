var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c(
        "v-layout",
        [
          _vm.isAuthenticated
            ? _c("top-nav", {
                attrs: {
                  isAuthenticated: _vm.isAuthenticated,
                  "notification-badge": _vm.busAlerts.length || 0,
                },
                on: {
                  "toggle-nav": function ($event) {
                    _vm.xsNavVisible = !_vm.xsNavVisible
                  },
                  "toggle-notifications": function ($event) {
                    _vm.showNotificationPanel = !_vm.showNotificationPanel
                  },
                  "toggle-mini": function ($event) {
                    _vm.mini = !_vm.mini
                  },
                  login: function ($event) {
                    return _vm.login()
                  },
                  logout: function ($event) {
                    return _vm.logout()
                  },
                },
              })
            : _vm._e(),
          _vm.isAuthenticated
            ? _c("left-nav", {
                attrs: {
                  mini: _vm.mini,
                  drawer: _vm.drawer,
                  xsNavVisible: _vm.xsNavVisible,
                  isAuthenticated: _vm.isAuthenticated,
                  left: "",
                },
                on: {
                  "toggle-mini": function ($event) {
                    _vm.mini = !_vm.mini
                  },
                  login: function ($event) {
                    return _vm.login()
                  },
                },
              })
            : _vm._e(),
          _c(
            "v-main",
            { staticClass: "offwhite", attrs: { app: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c("router-view", {
                    on: {
                      login: function ($event) {
                        return _vm.login()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.toastAlerts, function (alert) {
                return _c(
                  "v-snackbar",
                  {
                    key: alert.key,
                    attrs: {
                      top: "",
                      vertical: "",
                      "multi-line": "",
                      color: alert.color,
                      timeout: alert.timeout,
                    },
                    model: {
                      value: alert.active,
                      callback: function ($$v) {
                        _vm.$set(alert, "active", $$v)
                      },
                      expression: "alert.active",
                    },
                  },
                  [
                    _vm._v(_vm._s(alert.message) + " "),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.removeAlert(alert)
                          },
                        },
                      },
                      [_vm._v("Dismiss")]
                    ),
                  ],
                  1
                )
              }),
              _vm.showNotificationPanel
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "content-class": "top-right",
                        "max-width": "800",
                      },
                      model: {
                        value: _vm.showNotificationPanel,
                        callback: function ($$v) {
                          _vm.showNotificationPanel = $$v
                        },
                        expression: "showNotificationPanel",
                      },
                    },
                    [
                      _c("notification-panel", {
                        attrs: { notifications: _vm.busAlerts },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }