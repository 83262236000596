import Vue from 'vue';
import Router from 'vue-router';
import Login from './auth/Login.vue';
import store from './store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

Vue.use(Router);

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_AI_KEY,
    appId: process.env.VUE_APP_INSIGHTS_ID,
    /* ...Other Configuration Options... */
  },
});

appInsights.loadAppInsights();

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/orders',
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/jobs',
      name: 'Jobs',
      component: () => import('@/joblinking/JobLinking.vue'),
    },
    {
      path: '/branches',
      name: 'Branches',
      component: () => import('@/branches/Branches.vue'),
    },
    {
      path: '/customers',
      name: 'Customers',
      component: () => import('@/customers/Customers.vue'),
    },
    {
      path: '/users',
      name: 'Users',
      component: () => import('@/users/Users.vue'),
    },
    {
      path: '/products/vendorcatalog',
      name: 'Vendor Catalog',
      component: () => import('@/products/Products.vue'),
    },
    {
      path: '/products/crossreferences',
      name: 'Cross-References',
      component: () => import('@/products/CrossReferences.vue'),
    },
    {
      path: '/products/mappings/:companyId?/:search?',
      name: 'Mappings',
      component: () => import('@/products/Mappings.vue'),
    },
    {
      path: '/activityhistory',
      name: 'Activity',
      component: () => import('@/activityhistory/ActivityHistory.vue'),
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@/settings/Settings.vue'),
    },
    {
      path: '/account',
      name: 'Account',
      component: () => import('@/account/Account.vue'),
    },
    {
      path: '/callback',
      name: 'Callback',
      component: () => import('@/auth/Callback.vue'),
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('@/error/Error.vue'),
    },
    {
      path: '/subscription',
      name: 'Subscription',
      component: () => import('@/subscription/Subscription.vue'),
    },
    {
      path: '/billing',
      name: 'Billing',
      component: () => import('@/billing/Billing.vue'),
    },
    {
      path: '/sales',
      name: 'Sales',
      component: () => import('@/sales/Sales.vue'),
    },
    {
      path: '/requests',
      name: 'Requests',
      component: () => import('@/requests/Requests.vue'),
    },
    {
      path: '/orders',
      name: 'POs',
      component: () => import('@/orders/Orders.vue'),
    },
    {
      path: '/opportunities',
      name: 'Opportunities',
      component: () => import('@/opportunities/Opportunities.vue'),
    },
    {
      path: '/issues',
      name: 'Issues',
      component: () => import('@/issues/Issues.vue'),
    },
    {
      path: '/invite/:invitationId',
      name: 'Invite',
      props: true,
      component: () => import('@/invite/Invite.vue'),
    },
    {
      path: '/knowledge-base',
      beforeEnter() {
        window.open('https://gtpservices.atlassian.net/wiki/spaces/SK/pages/1073217537/GTP+Connect', '_blank');
      },
    },
    {
      path: '*',
      redirect: '/orders',
    },
  ],
});

router.beforeEach((to, from, next) => {
  const name = process.env.BASE_URL + ' / ' + to.name;
  appInsights.startTrackPage(name);
  next();
});

router.afterEach((route) => {
  const name = process.env.BASE_URL + ' / ' + route.name;
  const url = location.protocol + '//' + location.host + route.fullPath;
  appInsights.stopTrackPage(name, url);
});

export default router;
