import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/es5/util/colors';
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';

Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
});

const theme: VuetifyThemeVariant = {
  name: 'dark',
  primary: '#003E70',
  secondary: colors.grey.darken1,
  accent: colors.blue.accent3,
  success: colors.green.base,
  error: colors.red.darken1,
  info: colors.blue.base,
  warning: colors.yellow.base,
  offwhite: '#eeeeee',
  lightblue: '#e5ebf0',
  trialexpiration: '#EA611D',
};

const vuetify = new Vuetify();
vuetify.framework.icons.iconfont = 'mdi';
vuetify.framework.theme.dark = false;
vuetify.framework.theme.themes.light = theme;

export default vuetify;
